import { Button, DatePicker, Form, Input, Select } from "antd";
import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import { useGetApi } from "../../hooks/useGetApi";
import { useForm } from "antd/es/form/Form";
import { usePostApi } from "../../hooks/usePostApi";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { VALIDATE_TRIGGERS } from "../../utils/constants";
import { validateRequestNameOnChange } from "../../utils/validation";

export const RequestInfo = ({
  setActiveKey,
  form,
  closeModal,
  arrayOfCoaches,
}) => {
  const [coachIds, setCoachIds] = useState([]);

  const {
    data,
    isLoading,
    error,
    getData: getCoachData,
  } = useGetApi(`${process.env.REACT_APP_BASE_URL}/api/coaches/all/`);

  let coachesOptions = [];
  if (data) {
    data.forEach((coach) => {
      coachesOptions.push({
        label: `${coach.first_name} ${coach.last_name}`,
        value: coach.id,
      });
    });
  }

  const handleOnFinish = (values) => {
    setActiveKey("2");
  };
  const disabledDate = (current) => {
    // Get the current date
    const currentDate = moment().endOf("day");
    return current && current < currentDate.startOf("day");
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
  };

  useEffect(() => {
    if (arrayOfCoaches) {
      const selectedCoachIds = arrayOfCoaches.map((coach) => coach.id);
      form.setFieldsValue({ selectedCoach: selectedCoachIds });
    }
  }, [arrayOfCoaches, form]);

  return (
    <div>
      <Form form={form} onFinish={handleOnFinish}>
        <Form.Item
          name="requestName"
          label="Name of Request"
          validateTrigger={VALIDATE_TRIGGERS}
          rules={[
            {
              validator: validateRequestNameOnChange,
              validateTrigger: "onChange",
            },
          ]}
        >
          <Input maxLength={101} placeholder="Enter request name" />
        </Form.Item>
        <Form.Item
          name="selectedCoach"
          label="Select Coach(s)"
          rules={[
            {
              required: true,
              message: "Please select at least one coach",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            className="w-96 lg:w-full"
            placeholder="Please select coaches"
            options={coachesOptions}
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          ></Select>
        </Form.Item>
        <Form.Item
          name="requestExpiry"
          label="Expiry Date Of Request"
          rules={[
            {
              required: true,
              message: "Please select the expiry date Of request",
            },
          ]}
        >
          <DatePicker
            disabledDate={disabledDate} // Disable past dates
          />
        </Form.Item>
        <div className="flex justify-end space-x-2">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
