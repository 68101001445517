import React, { useMemo, useState } from "react";
import { Header } from "../../../components/header/Header";
import { Badge, Button, Form, Modal, Tabs } from "antd";
import { ActiveRequests } from "../../../components/active-requests/ActiveRequests";
import { ExpiredRequests } from "../../../components/expired-requests/ExpiredRequests";
import { CoachingSessionDates } from "../../../components/coaching-session-dates/CoachingSessionDates";
import { PageContent } from "../../../components/page-content/PageContent";
import { useEffect } from "react";
import { useGetApi } from "../../../hooks/useGetApi";
import { useLocation } from "react-router-dom";
import { RequestInfo } from "../../../components/request-info/RequestInfo";

const items = [
  {
    key: "1",
    label: "Active Requests",
    children: <ActiveRequests />,
  },
  {
    key: "2",
    label: "Expired Requests",
    children: <ExpiredRequests />,
  },
];

export const RequestAvailability = () => {
  const location = useLocation();
  const { arrayOfCoaches } = location.state || {};
  const [activeKey, setActiveKey] = useState("1");
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const { data, isLoading, error, getData } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/download_report/`
  );

  console.log({ arrayOfCoaches });
  const onChangeModal = (key) => {
    setActiveKey(key);
  };

  const createRequest = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const itemsModal = [
    {
      key: "1",
      label: "Request Info",
      children: (
        <RequestInfo
          closeModal={closeModal}
          form={form}
          setActiveKey={setActiveKey}
          arrayOfCoaches={arrayOfCoaches}
        />
      ),
    },
    {
      key: "2",
      label: "Coaching Session Dates",
      children: (
        <CoachingSessionDates
          setActiveTab={setActiveTab}
          closeModal={closeModal}
          form={form}
        />
      ),
      disabled: activeKey === "1",
    },
  ];

  useEffect(() => {
    if (arrayOfCoaches) {
      setIsModalOpen(true);
    }
  }, [arrayOfCoaches]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(items[0]);
    }
  }, [activeTab]);

  return (
    <div>
      <Header>Request Availability</Header>
      <PageContent>
        <div className="flex justify-end gap-2">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/schedular/download_report/`}
            type="secondary"
          >
            <Button>Download Available Slot</Button>
          </a>
          <Button type="primary" onClick={createRequest}>
            Create new request
          </Button>
        </div>
        <div className="flex mb-4">
          {items.map((tab) => {
            return (
              <div
                className={`flex items-center gap-4 p-2 px-4 cursor-pointer  ${
                  activeTab?.key === tab.key
                    ? "bg-primary-4 border-b-2 border-primary-1"
                    : "bg-white border-b-2"
                } `}
                onClick={() => setActiveTab(tab)}
              >
                <div
                  className={`${
                    activeTab?.key === tab.key ? "text-primary-2" : ""
                  }`}
                >
                  {tab?.label}
                </div>
              </div>
            );
          })}
        </div>
        {activeTab?.children}
      </PageContent>
      {isModalOpen && (
        <Modal
          footer={false}
          title="Request Availability"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
        >
          <Tabs
            activeKey={activeKey}
            defaultActiveKey="1"
            items={itemsModal}
            onChange={onChangeModal}
          />
        </Modal>
      )}
    </div>
  );
};
