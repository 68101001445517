import { EditOutlined, Search } from "@mui/icons-material";
import { Button, Form, Input, Modal, Select, Table, Tag } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Copy } from "../../components/copy/Copy";
import { Header } from "../../components/header/Header";
import { PageContent } from "../../components/page-content/PageContent";
import { useGetApi } from "../../hooks/useGetApi";
import { usePutApi } from "../../hooks/usePutApi";
import {
  SESSION_STATUS_OPTIONS,
  SESSION_STATUS_VALUES,
} from "../../utils/constants";

function isActive(timestamp) {
  // Get current time in milliseconds
  var currentTime = new Date().getTime();
  // Calculate 5 minutes before the given timestamp
  var fiveMinutesBefore = timestamp - 5 * 60 * 1000;
  // Calculate 30 minutes after the given timestamp
  var thirtyMinutesAfter = timestamp + 30 * 60 * 1000;
  // Check if the current time is within the specified range
  return currentTime >= fiveMinutesBefore && currentTime <= thirtyMinutesAfter;
}

export const AllSessions = () => {
  const [activeTab, setActiveTab] = useState("upcoming");

  const tabs = [
    {
      key: "upcoming",
      label: `Upcoming Sessions`,
    },
    {
      key: "past",
      label: `Past Sessions`,
    },
  ];

  return (
    <div>
      <Header>All Sessions</Header>
      <PageContent>
        <div className="flex mb-4">
          {tabs.map((tab) => {
            return (
              <div
                className={`flex items-center gap-4 p-2 px-4 cursor-pointer  ${
                  activeTab === tab.key
                    ? "bg-primary-4 border-b-2 border-primary-1"
                    : "bg-white border-b-2"
                } `}
                onClick={() => setActiveTab(tab.key)}
              >
                <div
                  className={`${activeTab === tab.key ? "text-primary-2" : ""}`}
                >
                  {tab?.label}
                </div>
              </div>
            );
          })}
        </div>
        <SessionsTable type={activeTab} />
      </PageContent>
    </div>
  );
};

function SessionsTable({ type }) {
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const userType = useMemo(() => {
    if (user) {
      return user.user.type;
    } else return undefined;
  }, [user]);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const [sessionToUpdateStatus, setSessionToUpdateStatus] = useState(null);

  const handleCloseModal = useCallback(() => {
    setSessionToUpdateStatus(null);
    form.resetFields();
  }, []);

  const {
    data: sessions,
    isLoading: sessionsLoading,
    // error: sessionsError,
    getData: getSessions,
  } = useGetApi(
    user &&
      `${process.env.REACT_APP_BASE_URL}/schedular/sessions/${type}/` +
        (userType === "coach" ? `?coach_id=${user.id}` : "")
  );

  const { data, isLoading, putData, resetState } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/update-session-status/${sessionToUpdateStatus?.id}/`
  );

  const updateSessionStatusHandler = useCallback(
    (values) => {
      putData({ status: values.status });
    },
    [putData]
  );

  const columns = [
    {
      title: "Project Details",
      dataIndex: "batch_name",
      key: "batch_name",
      hidden: userType === "coach",
      render: (_, session) => {
        return (
          <div className="flex flex-col">
            <Link
              className="m-hyperlink-cta"
              to={`/projects/progress/${session.project_id}`}
            >
              <p className="font-semibold">{session.project_name}</p>
            </Link>
            <p>{session.batch_name}</p>
          </div>
        );
      },
    },
    {
      title: "Coach",
      dataIndex: "coach_name",
      key: "coach_name",
      hidden: userType === "coach",
      render: (_, session) => (
        <div className="flex flex-col">
          <p className="font-semibold">{session.coach_name}</p>
          <p className="text-sm text-gray-500">{session.coach_email}</p>
          <p className="text-sm text-gray-500">{session.coach_phone}</p>
        </div>
      ),
    },
    {
      title: "Participant",
      dataIndex: "participant_name",
      key: "participant_name",
      render: (_, session) => (
        <div className="flex flex-col">
          <p className="font-semibold">{session.participant_name}</p>
          <p className="text-sm text-gray-500">{session.participant_email}</p>
          <p className="text-sm text-gray-500">{session.participant_phone}</p>
        </div>
      ),
    },
    {
      title: "Session Type",
      dataIndex: "coaching_session_number",
      key: "coaching_session_number",
      hidden: userType === "coach",
      render: (coaching_session_number, record) => (
        record.session_type === "mentoring_session"
          ? `Mentoring Session ${coaching_session_number}`
          : `Coaching Session ${coaching_session_number}`
      ),
    },
    {
      title: "Scheduled For",
      dataIndex: "start_time",
      key: "start_time",
      render: (_,session) => (
        <div className="flex flex-col">
          {console.log({session})}
          <p>{moment(new Date(+session.start_time)).format("DD-MM-YYYY")}</p>
          <p>{moment(new Date(+session.start_time)).format("hh:mm A")}-{moment(new Date(+session.end_time)).format("hh:mm A")}</p>
        </div>
      ),
    },
    {
      title: "Meeting Link",
      dataIndex: "meeting_link",
      key: "meeting_link",
      hidden: type === "past",
      render: (text, record) =>
        userType === "coach" ? (
          <Button
            disabled={!isActive(+record.start_time)}
            type="text"
            onClick={() =>
              navigate(`/coaching/join/${record.room_id}`, {
                state: { sessionDetails: record },
              })
            }
          >
            Join Meeting
          </Button>
        ) : (
          <Copy
            textToCopy={text}
            initialText={"Meeting Link"}
            finalText={"Link Copied"}
          />
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: type !== "past",
      render: (status, record) => (
        <div className="flex items-center flex-nowrap gap-2">
          <Tag
            color={
              status === "completed"
                ? "green"
                : status === "coachee_no_show"
                ? "red"
                : "default"
            }
          >
            {SESSION_STATUS_VALUES[status]}
          </Tag>
          <Button
            type="text"
            onClick={() => setSessionToUpdateStatus(record)}
            className="py-0 px-2"
            icon={<EditOutlined className="!text-[1rem]" />}
          ></Button>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    if (data) {
      handleCloseModal();
      getSessions();
      resetState();
    }
  }, [data, navigate, handleCloseModal, getSessions, resetState]);

  useEffect(() => {
    if (
      sessionToUpdateStatus &&
      (sessionToUpdateStatus?.status === "coachee_no_show" ||
        sessionToUpdateStatus?.status === "completed")
    ) {
      form.setFieldsValue({ status: sessionToUpdateStatus?.status });
    }
  }, [sessionToUpdateStatus, form]);

  const filteredSessions = sessions?.filter((session) => {
    const participantName = session?.participant_name;
    const participantEmail = session?.participant_email;
    const participantPhone = session?.participant_phone;
    const coachName = session?.coach_name;
    const coachEmail = session?.coach_email;
    const coachPhone = session?.coach_phone;
    const batchName = session?.batch_name;
    const projectName = session?.project_name;

    if (userType === "coach") {
      return (
        participantName
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        participantEmail
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        participantPhone
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase())
      );
    } else {
      return (
        participantName
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        participantEmail
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        participantPhone
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        coachName?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()) ||
        coachEmail
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        coachPhone
          ?.toLowerCase()
          ?.includes(searchTerm?.trim()?.toLowerCase()) ||
        batchName?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()) ||
        projectName?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase())
      );
    }
  });

  return (
    <>
      <Input
        className="mb-4 w-auto min-w-[20rem]"
        prefix={<Search />}
        type="text"
        placeholder="Search in project, coach or participant.."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Table
        loading={sessionsLoading}
        dataSource={filteredSessions || []}
        columns={columns}
      />
      {sessionToUpdateStatus && (
        <Modal
          onCancel={handleCloseModal}
          footer={null}
          title="Update session status."
          open={true}
        >
          <p>Participant Name: {sessionToUpdateStatus?.participant_name}</p>
          <Form
            form={form}
            className="mt-4 flex flex-col"
            labelAlign="left"
            onFinish={updateSessionStatusHandler}
          >
            <Form.Item
              name="status"
              label="Select status"
              rules={[
                {
                  required: true,
                  message: "Please select a status.",
                },
              ]}
            >
              <Select
                placeholder="Select a status"
                options={SESSION_STATUS_OPTIONS}
              />
            </Form.Item>
            <div className="flex gap-2 justify-end">
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Form.Item>
                <Button htmlType="submit" loading={isLoading} type="primary">
                  Update Status
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
}
